import {
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  HostListener,
  inject,
  Input,
  ViewChild,
  ViewEncapsulation,
  AfterViewInit,
} from '@angular/core';
import { type ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { WORKFLOW_TOKEN } from './rich-text-editor.constant';

declare const Quill;
const TOOLBAR_OPTIONS = [
  [{ header: [1, 2, 3, false] }],
  ['bold', 'italic', 'underline'],
  ['link'],
  [{ list: 'ordered' }, { list: 'bullet' }, 'image'],
];

const Embed = Quill.import('blots/embed');
class TextTokenBlot extends Embed {
  static blotName = 'textToken';
  static tagName = 'span';
  static className = 'text-token';

  static create(value: string) {
    const node: HTMLElement = super.create();
    node.textContent = value;
    return node;
  }

  static value(node: HTMLElement) {
    return node.textContent;
  }
}

@Component({
  selector: 'rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RichTextEditorComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [TranslateModule],
})
export class RichTextEditorComponent implements ControlValueAccessor, AfterViewInit {
  @Input() placeholder: string;
  @Input() textOnly: boolean;
  @Input() linkOption = true;
  @Input() maxLength: number;
  @Input() readOnly = false;
  @Input() jsonInput = false;
  @Input() customDropdown = false;
  @Input() workflowType = 'Product';

  @Input()
  @HostBinding('class.styled-text-tokens')
  styledTextTokens = true;

  @ViewChild('editorContainer') editorContainer: ElementRef;

  public remainingChars = 0;
  public isDisabled = false;
  private readonly elementRef = inject(ElementRef);
  private readonly translate = inject(TranslateService);
  private editor: any;

  private _value: any = null;

  public get value(): any {
    return this._value;
  }

  public set value(val: any) {
    this._value = val;
    this._value.html = this.getHtmlContent();
    this.onChange({
      ...val,
      html: this.getHtmlContent(),
    });
    this.onTouched();
  }

  @HostBinding('class.focused') get hasFocus(): boolean {
    return this.editor ? this.editor.hasFocus() : false;
  }

  @HostBinding('class.readonly') get isReadonly(): boolean {
    return this.readOnly;
  }

  @HostListener('click', ['$event']) onClick(event: MouseEvent): void {
    const textArea = this.elementRef.nativeElement.querySelector('.ql-container');
    const quillTooltip = textArea.querySelector('.ql-tooltip');

    if (!textArea || quillTooltip?.contains(event.target)) {
      return;
    }

    if (textArea.contains(event.target)) {
      this.editor.focus();
    }
  }

  public ngAfterViewInit(): void {
    this.registerInlineStyles();

    const options = [];

    if (this.textOnly) {
      options.push(...TOOLBAR_OPTIONS.slice(0, 2));

      if (this.linkOption) {
        options.push(TOOLBAR_OPTIONS[2]);
      }
    } else {
      options.push(...TOOLBAR_OPTIONS);
    }

    if (this.customDropdown) {
      options.push([{ textToken: WORKFLOW_TOKEN[this.workflowType].map((t) => t.label) }]);
    }

    this.editor = new Quill(this.editorContainer.nativeElement, {
      theme: 'snow',
      placeholder: this.placeholder,
      modules: {
        toolbar: this.readOnly ? false : { container: options, handlers: this.getHandlers() },
      },
      readOnly: !!this.readOnly,
    });

    if (this.readOnly) {
      this.editor.enable(false);
    }

    if (this._value) {
      this.editor.root.innerHTML = this._value.html;
    }

    if (+this.maxLength) {
      setTimeout(() => {
        this.remainingChars = +this.maxLength - this.editor.getLength() + 1;
      }, 0);
    }

    this.editor.on('text-change', (delta) => {
      this.value = this.editor.getContents();

      // Due to a lack of init event in Quill editor, we need to remove the double brackets from the html in the "text-change" event
      // This event is triggered when the editor is initialized and the text content is set
      // We do this only if the editor is in read-only mode, as in this case event is triggered only once, when the content has been set
      // @see https://github.com/quilljs/quill/issues/1552
      if (+this.maxLength) {
        const length = this.editor.getLength();
        if (this.editor.getLength() > this.maxLength) {
          this.editor.deleteText(this.maxLength, length);
          this.remainingChars = 0;
        } else {
          this.remainingChars = this.maxLength - length + 1;
        }
      }
    });

    if (this.customDropdown) {
      this.buildCustomDropdown();
    }
  }

  public writeValue(value: any): void {
    this._value = this.jsonInput && value ? JSON.parse(value) : value;
    if (this.editor) {
      this.editor.setContents(this._value);
    }
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public getHtmlContent(): string {
    return this.editor.root.innerHTML;
  }

  private onChange = (val: any) => {};

  private onTouched = () => {};

  private buildCustomDropdown(): void {
    const textPickerItems = Array.prototype.slice.call(document.querySelectorAll('.ql-textToken .ql-picker-item'));
    textPickerItems.forEach((item) => (item.textContent = item.dataset.value));
    document.querySelector('.ql-textToken').parentElement.style.float = 'right';
    document.querySelector('.ql-textToken .ql-picker-label').innerHTML =
      this.translate.instant('workflowsPage.buttons.insertDynamicText') +
      document.querySelector('.ql-textToken .ql-picker-label').innerHTML;
  }

  private getHandlers() {
    return this.customDropdown
      ? {
          textToken: function (value) {
            if (value) {
              const text = WORKFLOW_TOKEN.Product.find((t) => t.label === value)?.id;

              const range = this.quill.getSelection();
              this.quill.insertEmbed(range.index, 'textToken', text);

              setTimeout(() => this.quill.setSelection(range.index + 1, 0));
            }
          },
        }
      : {};
  }

  private registerInlineStyles(): void {
    const DirectionAttribute = Quill.import('attributors/attribute/direction');
    Quill.register(DirectionAttribute, true);

    const AlignClass = Quill.import('attributors/class/align');
    Quill.register(AlignClass, true);

    const BackgroundClass = Quill.import('attributors/class/background');
    Quill.register(BackgroundClass, true);

    const ColorClass = Quill.import('attributors/class/color');
    Quill.register(ColorClass, true);

    const DirectionClass = Quill.import('attributors/class/direction');
    Quill.register(DirectionClass, true);

    const FontClass = Quill.import('attributors/class/font');
    Quill.register(FontClass, true);

    const SizeClass = Quill.import('attributors/class/size');
    Quill.register(SizeClass, true);

    const AlignStyle = Quill.import('attributors/style/align');
    Quill.register(AlignStyle, true);

    const BackgroundStyle = Quill.import('attributors/style/background');
    Quill.register(BackgroundStyle, true);

    const ColorStyle = Quill.import('attributors/style/color');
    Quill.register(ColorStyle, true);

    const DirectionStyle = Quill.import('attributors/style/direction');
    Quill.register(DirectionStyle, true);

    const FontStyle = Quill.import('attributors/style/font');
    Quill.register(FontStyle, true);

    const SizeStyle = Quill.import('attributors/style/size');
    Quill.register(SizeStyle, true);

    Quill.register(TextTokenBlot);
  }
}

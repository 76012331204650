export const COMPANY_WORKFLOW_TOKEN = [
  {
    id: 'TaskAssignerCompanyName',
    label: 'Task Assigner Company Name',
  },
  {
    id: 'TaskAssignerName',
    label: 'Task Assigner Name',
  },
  {
    id: 'TaskRecipientCompanyName',
    label: 'Task Recipient Company Name',
  },
];

export const PRODUCT_WORKFLOW_TOKEN = [
  ...COMPANY_WORKFLOW_TOKEN,
  {
    id: 'ProductGTIN',
    label: 'Product GTIN / UPC',
  },
  {
    id: 'ProductItemNumber',
    label: 'Product Item Number',
  },
  {
    id: 'ProductMPC',
    label: 'Product MPC',
  },
  {
    id: 'ProductName',
    label: 'Product Name',
  },
];

export const WORKFLOW_TOKEN = {
  Product: PRODUCT_WORKFLOW_TOKEN,
  Company: COMPANY_WORKFLOW_TOKEN,
};
